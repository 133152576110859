import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

import { useState } from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import fr from "date-fns/locale/fr";

import Button from "../micro_components/Button";
import InputIcon from "../micro_components/InputIcon";
import Mail from "../micro_components/svg/Mail";
import User from "../micro_components/svg/User";
import PaymentForm from "./PaymentForm";
import actionPaymentIntent from "../asyncActions/paymentIntend";
import { calendarSelectToFormattedDate } from "../functions/calendarSelectToFormattedDate";
import actionFreeProduct from "../asyncActions/freeProduct";
import actionGetAllAppointments from "../asyncActions/getAllAppointments";
import { toast } from "sonner";

function Calendar({ setIsBooking }) {
  const dispatch = useDispatch();

  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedHour, setSelectedHour] = useState("");
  // const [selectedDuration, setSelectedDuration] = useState(null)
  const [availableHours, setAvailableHours] = useState([]);
  const [whatShow, setWhatShow] = useState("calendar");
  const [checkTerms, setCheckTerms] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [appointment, setAppointment] = useState("");
  const [themeElement, setThemeElement] = useState({ theme: "stripe" });
  const [noAppointmentAvailable, setNoAppointmentAvailable] = useState(false);

  const isLogged = useSelector((state) => state.user.isLogged);
  const currentProduct = useSelector((state) => state.products.currentProduct);
  const clientSecret = useSelector((state) => state.cart.clientSecret);
  const allAppointments = useSelector(
    (state) => state.appointments.allAppointments
  );
  const isEbook = currentProduct.product.category.name === "Ebook";
  const classBody = document.body.classList;
  const appearance = themeElement;
  let stripePromise;
  if (process.env.REACT_APP_DEBUG === "true") {
    stripePromise = loadStripe(process.env.REACT_APP_LOCAL_STRIPE_PUBLIC_KEY, {
      stripeAccount: process.env.REACT_APP_STRIPE_ACCT,
    });
  } else {
    stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY, {
      stripeAccount: process.env.REACT_APP_STRIPE_ACCT,
    });
  }

  // No appointment for ebooks (don't show calendar and hour)
  useEffect(() => {
    if (currentProduct.product.category && isEbook) {
      setWhatShow("information");
    } else {
      dispatch(actionGetAllAppointments());
    }
  }, [currentProduct, isEbook, dispatch]);

  // Click to select a new date function
  const handleDateChange = (date) => {
    setSelectedDate(date);
    const day = date.getDay();
    const arrayAvailableHours = [
      "09:00",
      "10:00",
      "11:00",
      "12:00",
      "13:00",
      "14:00",
      "15:00",
      "16:00",
      "17:00",
      "18:00",
      "19:00",
      "20:00",
      "21:00",
    ];
    if (allAppointments) {
      const appointmentsToCurrentDate = allAppointments.filter(
        (appointment) => {
          return (
            date.getMonth() + 1 === appointment.planedToDetails.month &&
            date.getDate() === appointment.planedToDetails.day
          );
        }
      );

      const takenHours = appointmentsToCurrentDate.map((appointment) =>
        appointment.planedToDetails.hour.toString()
      );
      const filteredHours = arrayAvailableHours.filter(
        (hour) => !takenHours.includes(hour.split(":")[0])
      );
      if (day === 6 || day === 0) {
        setAvailableHours(filteredHours);
        if (filteredHours.length === 0) {
          setNoAppointmentAvailable(true);
        }
      } else {
        setAvailableHours(
          filteredHours.filter((hour) =>
            ["18:00", "19:00", "20:00", "21:00"].includes(hour)
          )
        );
        if (filteredHours.length === 9) {
          setNoAppointmentAvailable(true);
        }
      }
    } else {
      toast.error(
        "Un problème est survenu sur le calendrier, veuillez réessayer plus tard"
      );
    }

    setWhatShow("hour");
  };

  // // Click to select a new duration function
  // const handleDurationChange = (event) => {
  //   setSelectedDuration(event.target.value);
  // };

  // // Click to validate duration
  // const handleDurationValidation = () => {
  //   setWhatShow('hour');
  // };

  // Click to select a new hour function
  const handleHourChange = (event) => {
    setSelectedHour(event.target.value);
  };

  // Click to validate appointment
  const handleAppointmentValidation = () => {
    if (!isLogged) {
      setWhatShow("information");
    } else {
      setWhatShow("payment");
    }
    setAppointment(calendarSelectToFormattedDate(selectedDate, selectedHour));
  };

  // Click to validate user information and proceed to payment
  const handleInformationValidation = (e) => {
    e.preventDefault();
    if (currentProduct.product.price === "0.00") {
      dispatch(
        actionFreeProduct({
          name,
          mail: email,
          productId: currentProduct.product.id,
          termsAccepted: checkTerms,
        })
      );
    } else {
      if (currentProduct.product.category.name === "Ebook") {
        setAppointment(null);
      }
      dispatch(
        actionPaymentIntent({
          appointment,
          duration: "60",
          name,
          email,
          productId: currentProduct.product.id,
          termsAccepted: checkTerms,
        })
      );
      setWhatShow("payment");
    }
  };

  // Days not available
  const daysNotAvailable = (date) => {
    const today = new Date();
    const tomorrow = new Date(today);
    const dayAfterTomorrow = new Date(today);

    // Set date for tomorrow and after tomorrow
    tomorrow.setDate(today.getDate() + 1);
    dayAfterTomorrow.setDate(today.getDate() + 2);

    const month = date.getMonth().toString();
    const day = date.getDate();

    // Unavailable days by month (object keys are month and array keys are day)
    const objectFilteredDaysByMonth = {
      0: [1, 2, 10, 20],
      1: [2, 8, 12],
      2: [10, 12],
      3: [25, 28],
    };

    // Check current date and day+1 / +2
    if (
      date.getDate() === today.getDate() ||
      date.getDate() === tomorrow.getDate() ||
      date.getDate() === dayAfterTomorrow.getDate()
    ) {
      return false;
    }

    // Vérification des jours spécifiques définis par mois
    if (
      Object.keys(objectFilteredDaysByMonth).includes(month) &&
      objectFilteredDaysByMonth[month].includes(day)
    ) {
      return false;
    }

    return true;
  };

  // Custom payment form if it is in dark mode or not
  useEffect(() => {
    if (classBody.contains("dark")) {
      setThemeElement({
        theme: "night",
        variables: {
          colorBackground: "#4a4c4f",
        },
      });
    } else {
      setThemeElement({ theme: "stripe" });
    }
  }, [classBody]);

  // Options for Payment Form (by Stripe)
  const options = {
    clientSecret,
    appearance,
  };

  return (
    <div
      className={` p-4 border border-gray-200 rounded-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white ${
        whatShow === "calendar"
          ? "flex flex-col justify-center items-center "
          : ""
      }`}
    >
      {whatShow === "calendar" && (
        <>
          <h3 className="mb-5 text-lg font-medium text-gray-900 dark:text-white text-center">
            Sélectionne un jour
          </h3>
          <DatePicker
            selected={selectedDate}
            onChange={handleDateChange}
            dateFormat="dd-MM-yyyy"
            inline
            filterDate={daysNotAvailable}
            locale={fr}
            dark
            minDate={new Date()}
          />
        </>
      )}
      {/* {whatShow === 'duration' && 
    (
      <>
      <div className="flex items-center justify-between mb-3">
          <button onClick={() => {setWhatShow('calendar'); setSelectedDuration(null)}}>
            <i className="fa-solid fa-arrow-left"></i>
          </button>
          <h3 className="text-lg flex-grow font-medium text-gray-900 dark:text-white text-center">Sélectionne une durée</h3>
        </div>
        <ul className="w-full flex flex-wrap justify-between" >
          <li  className="my-3 w-[45%]">
              <input type="radio"  name="hosting" onChange={handleDurationChange} value={30} id="30" className="hidden peer" required />
              <label htmlFor="30" className="block text-center w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-400 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600  dark:text-gray-400 dark:bg-transparent">                           
                30 minutes
              </label>
          </li>
          <li  className="my-3 w-[45%]">
              <input type="radio"  name="hosting" onChange={handleDurationChange} value={60} id="60" className="hidden peer" required />
              <label htmlFor="60" className="block text-center w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-400 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600  dark:text-gray-400 dark:bg-transparent">                           
                1 heure
              </label>
          </li>
        </ul>
        {selectedDuration && <Button onClick={handleDurationValidation} text={'Valider'} className="my-3 mx-auto"></Button>}
      </>
      
    )} */}
      {whatShow === "hour" && (
        <>
          <div className="flex items-center justify-between mb-3">
            <button
              onClick={() => {
                setWhatShow("duration");
                setNoAppointmentAvailable(false);
              }}
            >
              <i className="fa-solid fa-arrow-left"></i>
            </button>
            <h3 className="text-lg flex-grow font-medium text-gray-900 dark:text-white text-center">
              Sélectionne un horaire
            </h3>
          </div>

          <p className="capitalize font-medium text-center text-gray-500 dark:text-gray-300 ">
            {selectedDate.toLocaleDateString("fr-FR", {
              weekday: "long",
              day: "2-digit",
              month: "long",
              year: "numeric",
            })}
          </p>
          {noAppointmentAvailable && (
            <p className="italic text-center text-sm text-gray-500 dark:text-gray-300 mt-4">
              Pas de créneau disponible pour cette date
            </p>
          )}

          <ul className="w-full flex flex-wrap justify-between">
            {availableHours.map((hour, index) => (
              <li key={index} className="my-3 w-[45%]">
                <input
                  type="radio"
                  id={index}
                  name="hosting"
                  onChange={handleHourChange}
                  value={hour}
                  className="hidden peer"
                  required
                />
                <label
                  htmlFor={index}
                  className="block text-center w-full p-3 text-gray-500 bg-white border border-gray-200 rounded-lg cursor-pointer dark:hover:text-gray-300 dark:border-gray-400 dark:peer-checked:text-blue-500 peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600  dark:text-gray-400 dark:bg-transparent"
                >
                  {hour}
                </label>
              </li>
            ))}
          </ul>
          {selectedHour !== "" && (
            <Button
              onClick={handleAppointmentValidation}
              text={isLogged ? "Passer au paiement" : "Valider"}
              className="my-3 mx-auto"
            ></Button>
          )}
        </>
      )}
      {whatShow === "information" && (
        <>
          <div className="flex items-center justify-between mb-3">
            {!isEbook && (
              <button
                onClick={() => {
                  setWhatShow("hour");
                  setSelectedHour("");
                }}
              >
                <i className="fa-solid fa-arrow-left"></i>
              </button>
            )}
            <h3 className="text-lg font-medium text-gray-900 flex-grow dark:text-white text-center">
              Informations personnelles
            </h3>
          </div>
          {!isEbook && (
            <>
              <p className="text-center font-bold">Rendez-vous le :</p>
              <p className="capitalize mb-6 font-medium text-center text-gray-500 dark:text-gray-300">
                {selectedDate.toLocaleDateString("fr-FR", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }) +
                  " à " +
                  selectedHour}
              </p>
            </>
          )}

          <form onSubmit={handleInformationValidation}>
            <label
              htmlFor="website-admin"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Nom complet
            </label>
            <InputIcon
              iconSvg={<User />}
              placeholder={"Jean DUPONT"}
              id={"website-admin"}
              className={"mb-6"}
              value={name}
              onChange={setName}
              required={true}
            />
            <label
              htmlFor="input-group-1"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Ton email
            </label>
            <InputIcon
              type={"email"}
              iconSvg={<Mail />}
              placeholder={"jeveux@unjob.fr"}
              id={"input-group-1"}
              className={"mb-6"}
              value={email}
              onChange={setEmail}
              required={true}
            />
            <div className="flex items-center mb-5">
              <input
                id="link-checkbox"
                type="checkbox"
                checked={checkTerms}
                onChange={() => setCheckTerms(!checkTerms)}
                className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
              />
              <label
                htmlFor="link-checkbox"
                className="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                J'ai lu et compris{" "}
                <a
                  target="_blank"
                  href="/cgv"
                  className="text-blue-600 dark:text-blue-500 hover:underline"
                >
                  les conditions générales de ventes
                </a>
                .
              </label>
            </div>
            <Button
              type="submit"
              text={"Passer au paiement"}
              disabled={!checkTerms}
              className="mx-auto"
            ></Button>
          </form>
        </>
      )}
      {whatShow === "payment" && (
        <>
          <h3 className="text-lg font-medium text-gray-900 flex-grow dark:text-white text-center">
            Paiement
          </h3>
          {isEbook ? (
            <p className="capitalize mb-6 font-medium text-center text-gray-500 dark:text-gray-300">
              {currentProduct.product.title}
            </p>
          ) : (
            <>
              <p className="text-center font-bold">
                Rendez-vous {"60" === "30" ? "de 30 minutes" : "d'une heure"} le
                :
              </p>
              <p className="capitalize mb-6 font-medium text-center text-gray-500 dark:text-gray-300">
                {selectedDate.toLocaleDateString("fr-FR", {
                  weekday: "long",
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
                }) +
                  " à " +
                  selectedHour}
              </p>
            </>
          )}

          {!clientSecret ? (
            <div className="w-fit m-auto mt-10">
              <div className="border-gray-300 h-10 w-10  animate-spin rounded-full border-8 border-t-blue-600" />
            </div>
          ) : (
            <Elements options={options} stripe={stripePromise}>
              <PaymentForm />
            </Elements>
          )}
        </>
      )}
      <button
        onClick={() => {
          setIsBooking(false);
        }}
        type="button"
        className="mt-3 flex mx-auto text-gray-800 text-xs rounded-lg p-2.5 text-center items-center gap-2 "
      >
        <span className="dark:text-white">Annuler la réservation</span>
        <span className="sr-only">Annuler</span>
      </button>
    </div>
  );
}

export default Calendar;
