// import Mail from "../micro_components/svg/Mail";
// import Soleil from "../micro_components/svg/Soleil";

function Include({ icon, title, description }) {
  // const iconComponents = {
  //   Mail: Mail,
  //   Soleil: Soleil
  // };
  // const IconComponent = iconComponents[icon];
  return (
    <div className="w-full block p-6 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
      {/* {IconComponent && <IconComponent />} */}
      <h5 className="mb-2 text-lg font-semibold tracking-tight text-gray-900 dark:text-white">
        {title}
      </h5>
      <p className="mb-3 font-normal text-gray-500 dark:text-gray-400">
        {description}
      </p>
    </div>
  );
}

export default Include;
